

import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#061E4D',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

export default CustomTooltip;