import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const ApiContext = createContext();

export const AuthProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [api, setApi] = useState(null);

    useEffect(() => {
        const initializeApi = async () => {
            const apiInstance = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            apiInstance.interceptors.request.use(
                async (config) => {
                    try {
                        const token = await getAccessTokenSilently({
                            authorizationParams: {
                                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                                scope: 'read:current_user',
                            },
                        });

                        config.headers.Authorization = `Bearer ${token}`;
                    } catch (error) {
                        console.error('Error fetching access token', error);
                        return Promise.reject(error);
                    }

                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
            apiInstance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error.response && error.response.status === 401) {
                        // Handle 401 Unauthorized response if needed
                    }
                    return Promise.reject(error);
                }
            );
            setApi(() => apiInstance);
        };
        initializeApi();
    }, [getAccessTokenSilently]);

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    return useContext(ApiContext);
};
