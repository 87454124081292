import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../../AuthProvider';

const WhatsNewModal = ({isWhatsNewModalOpen = false, setIsWhatsNewModalOpen, user }) => {
    const navigate = useNavigate();
    const api = useApi();

    React.useEffect(() => {
        if (window.location.pathname === '/' && user !== null && user !== undefined && user?.seen_latest_features === false) {
            //TODO this doesn't seem efficient
            setIsWhatsNewModalOpen(true);
        }
    }, [window.location.pathname, user]);

    return (
        <React.Fragment>
            <Dialog
                open={isWhatsNewModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{maxWidth:'900px', margin:'auto'}}
            >
                <DialogTitle id="alert-dialog-title">
                    <h3>{"Recent Updates"}</h3>
                </DialogTitle>
                <DialogContent className="whats-new-content">
                    <section>
                        <h3>Dec 13, 2024</h3>
                        <ul>
                            <li>
                                <strong>Fresh Design:</strong>&nbsp;We've revamped the Segment and Question creation experience with a cleaner, more intuitive look to make building surveys easier and clearer.
                            </li>
                            <li>
                                <strong>New Time-Saving Feature:</strong>&nbsp;You can now copy Segments and Questions effortlessly, helping you create surveys faster and with less effort.
                            </li>
                            <li>
                                <strong>Improved Question Design:</strong>&nbsp;Single Choice and Multiple Choice questions are now even easier to work with! Add question response choices one at a time by clicking “Add Choice” or by pressing “enter/return” on your keyboard. You can also easily add, delete, or reorder choices with a simple drag-and-drop.
                            </li>
                            <li>
                                <strong>Smoother Experience:</strong>&nbsp;We’ve enhanced the "Bulk Upload" feature, improved the survey results data export to give you more comprehensive survey data, and fixed usability issues to make survey creation smoother than ever.
                            </li>
                        </ul>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        if (!user?.seen_latest_features) {
                            // flip the seen_latest_features flag until the next release
                            const featuresResponse = await api.post(`/users/seen-latest-features`);
                            if (featuresResponse && featuresResponse.status !== 200) {
                                console.error('Error changing seen_latest_features');
                            }
                        }
                        setIsWhatsNewModalOpen(false);
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default WhatsNewModal;