// DynamicChipList component is a text input with an add button that allows user to create
// a list of chips. Chips can be deleted and drag/drop sorted left to right
import * as React from 'react';
import { Chip } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import {
    DndContext,
    useDroppable,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";


const DynamicChipList = ({
    listData,
    setListData,
    parentLabel,
    label
}) => {

    // dnd-kit sensors
    const sensors = useSensors(
        useSensor(MouseSensor, {
          // Require the mouse to move by 10 pixels before activating
          activationConstraint: {
            distance: 10,
          },
        }),
        useSensor(TouchSensor, {
          // Press delay of 250ms, with tolerance of 5px of movement
          activationConstraint: {
            delay: 250,
            tolerance: 5,
          },
        })
      );
    // the ui passes listData which is a simple array, but every item within the chip list (to
    // be dragged and dropped) needs to be an object with an id
    const newListData = listData.map((item, index) => {
        return {
            id: index+1,
            label: item
        }
    });
    const [tempListData, setTempListData] = React.useState(newListData);
    const [newLabel, setNewLabel] = React.useState('');
    const [optionsInvalid, setOptionsInvalid] = React.useState(false);
    const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);
    // this converts the tempListData array of objects back into a simple array to bubble up to the parent
    const convertToArray = (tempListData) => {
        return tempListData.map((item) => item.label);
    }
    // saving this for later, in case we want to add validation to the input
    const validateOptions = (value) => {
        setNewLabel(value);
    }
    // adds a new item to the list and also updates parent list
    const handleItemAdd = (newItem) => {
        console.log('Adding item', newItem);
        if (newLabel === '' || newLabel === null || newLabel === undefined) {
            setOptionsInvalid(true);
            return;
        } else {
            setOptionsInvalid(false);
            // update the list UI
            setTempListData([...tempListData, { id: tempListData.length+1, label: newLabel }]);
            setNewLabel('');
            // update the parent object
            setListData(convertToArray([...tempListData, { id: tempListData.length, label: newLabel }]));
        }
    }
    // deletes an item from the list and also updates parent list
    const handleItemDelete = (item) => {
        console.log('Deleting item', item);
        console.log('tempListData', tempListData);
        setTempListData(tempListData.filter((chip) => chip !== item));
        tempListData.filter((chip) => chip !== item).length === 0 ? setOptionsInvalid(true) : setOptionsInvalid(false);
    }

    const handleDragStart = (e) => {
        //console.log('drag start', e);
    };
    const handleDragEnd = (e) => {
        console.log('drag end', e);
        // when user lets go, if the item being dragged isn't on top of something it recognizes (another draggable), nothing happens
        if (!e.over) return;
        console.log('listData start', listData);

        if (e.active.id !== e.over.id) {
            setTempListData((tempListData) => {
                // find the original index of the dragged item
                const oldIndex = tempListData.indexOf(tempListData.find(item => item.id.toString() === e.active.id.toString()));
                // find the index of the item that the dragged item is being dropped onto
                const newIndex = tempListData.indexOf(tempListData.find(item => item.id.toString() === e.over.id.toString()));
                console.log('oldIndex', oldIndex, 'newIndex', newIndex);
                const newArray = arrayMove(tempListData, oldIndex, newIndex);
                // newArray has correct index order but incorrect sort order, so sync them up
                newArray.map((item, index) => item.sort_order = index + 1);
                console.log('newArray', newArray);
                return newArray;
            });
            console.log('listData', listData);

        }
    }

    React.useEffect(() => {
        setListData(convertToArray(tempListData));
        console.log('tempListData', tempListData);
        console.log('listData', listData);
    }, [tempListData]);

    const Droppable = () => {
        // const { isOver, setNodeRef } = useDroppable({
        //     id: listId,
        //     data: { type: 'droppable' },
        //     strategy: horizontalListSortingStrategy,
        // });
        
        return (
            <Box className="chips">
                <SortableContext items={tempListData} strategy={horizontalListSortingStrategy}>
                {tempListData.map((item, index) => (
                    <DraggableChip
                        key={index}
                        chipKey={index}
                        label={item.label}
                        item={item}/>
                ))}
                </SortableContext>
            </Box>
        )};

    const DraggableChip = ({
        item,
        chipKey,
        label,
    }) => {
        console.log('inside DraggableChip', label, item, chipKey);
        // drag and drop functionality
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
        } = useSortable(item);
        // console.log('inside Draggable', label, item, id, index, isSortable);
        const itemStyle = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <Chip
            key={chipKey}
            ref={setNodeRef}
            style={itemStyle}
            label={label}
            onDelete={() => handleItemDelete(item)}
            className={`${parentLabel}-${label}-chip chip`}
            {...attributes}
            {...listeners}/>
        );
    };

    return (
        <Box className="dynamic-chip-list">
            <Box className="dynamic-card-row">
                <TextField
                    id={`${parentLabel}-${label}-input`}
                    label={`${label}s`}
                    data-test-id={`${parentLabel}-${label}-input`}
                    tabIndex="1"
                    fullWidth
                    sx={{ marginRight: '10px'}}
                    error={optionsInvalid}
                    helperText={optionsInvalid ? `Please add at least one ${label}` : `Enter a single ${label} and click 'Add ${formattedLabel}'`}
                    name={`${parentLabel}-${label}`}
                    value={newLabel}
                    className={`${parentLabel}-${label}-input ${optionsInvalid ? 'has-error' : ''}`}
                    onChange={(e) => validateOptions(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            handleItemAdd(newLabel);
                        }
                    }}
                    />
                <Button
                data-test-id={`add-${parentLabel}-${label}-btn`}
                onClick={() => handleItemAdd(newLabel)}
                variant="contained"
                sx={{ minWidth: '150px'}}
                className="crowdwave-blue"
                >
                Add {formattedLabel}
                </Button>
            </Box>
            <Box className="dynamic-card-row">
                <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sensors={sensors}>
                    <Droppable />
                </DndContext>
            </Box>
        </Box>
    );
}

export default DynamicChipList;