import * as React from 'react';
import { Box } from '@mui/material';
import { useApi } from '../../AuthProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DndContext, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { RadioGroup, FormControl, FormControlLabel, Radio, FormGroup, Checkbox, InputLabel } from '@mui/material';
import DynamicChipList from './DynamicChipList';

// depending on the type of item (label), render different edit and view layouts
const SegmentEditLayout = ({
    item,
    items,
    setListData,
    label,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    segmentSizeLimit,
    persistedError,
    setPersistedError
}) => {
    // find the index of the item in items
    const index = items.indexOf(item);
    const [nameEmpty, setNameEmpty] = React.useState(false);
    const [sizeZero, setSizeZero] = React.useState(false);
    const [sizeExceedsLimit, setSizeExceedsLimit] = React.useState(false);

    console.log('segmentSizeLimit', segmentSizeLimit);
    React.useEffect(() => {
        if (sizeExceedsLimit || sizeZero || nameEmpty) {
            setHasItemError(true);
        } else {
            setHasItemError(false);
        }
    }, [sizeExceedsLimit, sizeZero, nameEmpty, hasItemError]);
    return (
        <Box className="segment-edit">
            <Box className="dynamic-card-field">
                <TextField
                    id={`segment-size-input-${index+1}`}
                    className={`segment-size-input ${(sizeExceedsLimit || sizeZero) ? 'has-error' : ''}`}
                    label="Segment Size"
                    data-test-id={`segment-size-input-${index+1}`}
                    tabIndex={`${(index+1) * 2}`}
                    type="number"
                    error={sizeExceedsLimit || sizeZero}
                    helperText={(sizeExceedsLimit || sizeZero) ? 'Value must be between 1 and 1000' : ''}
                    max={10}
                    value={item.segment_size}
                    sx={{ width: '175px' }}
                    onFocus={(e) => {
                        let segmentSize = e.target.value;
                        segmentSize === 0 ? segmentSize = '' : segmentSize = segmentSize;
                    }}
                    onChange={(e) => {
                        let segmentSize = e.target.value;
                        // restrict values from min/max and prevent empties
                        segmentSize < 0 ? segmentSize = 0 : segmentSize = segmentSize;
                        segmentSize > 1000 ? segmentSize = 1000 : segmentSize = segmentSize;
                        // set appropriate error states to bubble up from card to list
                        if (parseInt(segmentSize) > segmentSizeLimit) {
                            setSizeExceedsLimit(true);
                            setSizeZero(false)
                        } else if (parseInt(segmentSize) < 1 || segmentSize === '') {
                            setSizeZero(true);
                        } else {
                            setSizeExceedsLimit(false);
                            setSizeZero(false);
                        }
                        // find the corresponding item in items and update the value
                        console.log('hasItemError', hasItemError);
                        items.map((listItem) => {
                            if (listItem.id === item.id) {
                                item.segment_size = segmentSize;
                            }
                        });
                        setListData([...items]);
                    }}/>
            </Box>
            <Box className="dynamic-card-field">
                <TextField
                    id={`segment-name-input-${index+1}`}
                    className={`segment-name-input ${nameEmpty ? 'has-error' : ''}`}
                    label="Segment Name"
                    data-test-id={`segment-name-input-${index+1}`}
                    tabIndex={`${(index+2) * 2}`}
                    error={nameEmpty}
                    helperText={nameEmpty ? 'Please add a segment name' : ''}
                    fullWidth
                    value={item.segment_name}
                    onChange={(e) => {
                        // find the corresponding item in items and update the value
                        e.target.value === '' ? setNameEmpty(true) : setNameEmpty(false);
                        items.map((listItem) => {
                            if (listItem.id === item.id) {
                                item.segment_name = e.target.value;
                            }
                        });
                        setListData([...items]);
                    }}/>
            </Box>
        </Box>
    );
};

const SegmentViewLayout = ({
    item,
}) => {
    return (
        <Box className="segment-view" key={item.id}>
            <span className="segment-name">{item.segment_name}</span>
            <span className="segment-size">
                <strong>{item.segment_size} Respondents</strong>
            </span>
        </Box>
    );
};

const QuestionEditLayout = ({
    item,
    items,
    setListData,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    label
}) => {
    const index = items.indexOf(item);
    const [textEmpty, setTextEmpty] = React.useState(false);
    const [optionsInvalid, setOptionsInvalid] = React.useState(false);
    return (
        <Box className="question-edit">
            <Box className="dynamic-card-row">
            <Box className="dynamic-card-field" sx={{flexGrow: 2}}>
            <TextField
                id={`question-text-input-${index+1}`}
                className={`question-text-input ${textEmpty ? 'has-error' : ''}`}
                label="Question Text"
                data-test-id={`question-text-input-${index+1}`}
                tabIndex={`${(index+1) * 2}`}
                error={textEmpty}
                fullWidth
                multiline
                rows={4}
                maxRows={4}
                value={item.question_text}
                helperText={textEmpty ? 'Please add a question' : ''}
                sx={{ flexGrow: 2 }}
                onChange={(e) => {
                    // find the corresponding item in items and update the value
                    items.map((listItem) => {
                        if (listItem.id === item.id) {
                            item.question_text = e.target.value;
                        }
                    });
                    if (e.target.value === '') {
                        setTextEmpty(true);
                    } else {
                        setTextEmpty(false);
                    }
                    setListData([...items]);
                }}/>
            </Box>
            <Box
            sx={{ width: '200px' }}
            className="dynamic-card-field">
                <FormControl>
                <InputLabel shrink htmlFor="select-multiple-native">
                Question Type
                </InputLabel>
                <Select
                className="question-type-input"
                value={item.question_type === '' ? 'short response' : item.question_type}
                onChange={(e) => {
                    // find the corresponding item in items and update the value
                    items.map((listItem) => {
                        if (listItem.id === item.id) {
                            item.question_type = e.target.value;
                        }
                    });
                    setListData([...items]);
                    // clear out options for questions that dont need them
                    if (item.question_type.toLowerCase() !== 'single choice' && item.question_type.toLowerCase() !== 'multiple choice') {
                        item.raw_options = '';
                        item.options = [];
                    }
                }}>
                    <MenuItem value="short response">Short Response</MenuItem>
                    <MenuItem value="single choice">Single Choice</MenuItem>
                    <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                    <MenuItem value="numeric rating">Numeric Rating</MenuItem>
                    </Select>
                    </FormControl>
                </Box>
            </Box>
            {(item.question_type.toLowerCase() === 'single choice' || item.question_type.toLowerCase() === 'multiple choice') &&
            <>
            {/* <Box className="dynamic-card-row">
                <Box className="dynamic-card-field" sx={{width: '100%'}}>
                    <TextField
                    id={`question-options-input-${index}`}
                    className={`question-options-input ${optionsInvalid ? 'has-error' : ''}`}
                    label='Options'
                    data-test-id={`question-options-input-${index}`}
                    tabIndex={`${(index+1) * 2}`}
                    fullWidth
                    error={optionsInvalid}
                    value={item.raw_options}
                    helperText={optionsInvalid ? 'Please add comma-separated options' : ''}
                    onChange={(e) => {
                        // find the corresponding item in items and update the value
                        items.map((listItem) => {
                            if (listItem.id === item.id) {
                                item.raw_options = e.target.value;
                                item.options = e.target.value.split(',').map((option) => option.trim());
                            }
                        });
                        if (e.target.value === '' && (item.question_type.toLowerCase() === 'single choice' || item.question_type.toLowerCase() === 'multiple choice')) {
                            setOptionsInvalid(true);
                        } else {
                            setOptionsInvalid(false);
                        }
                        setListData([...items]);
                    }}/>
                </Box>
            </Box> */}
            <Box className="dynamic-card-row">
                <Box className="dynamic-card-field" sx={{width: '100%'}}>
                    <DynamicChipList
                        listData={item.options}
                        setListData={(newOptions) => {
                            items.map((listItem) => {
                                if (listItem.id === item.id) {
                                    item.options = newOptions;
                                }
                            });
                            setListData([...items]);
                        }}
                        parentLabel={'question'}
                        label={'choice'}
                    />
                </Box>
            </Box>
            </>
            }
            </Box>
    );
};

const QuestionViewLayout = ({
    item
}) => {
    return (
        <Box className="question-view" key={item.id}>
            <span className="question-type"><strong>{item.question_type}</strong></span>
            <span className="question-text">{item.question_text}</span>
            { (item.question_type.toLowerCase() === 'short response' || item.question_type.toLowerCase() === 'long response') &&
                <span className="text-response">
                <TextField
                    disabled
                    fullWidth
                    sx={{ color: 'red'}}
                    value={`${item.question_type.charAt(0).toUpperCase() + item.question_type.slice(1)} text`}/>
                </span>
            }
            {item.question_type.toLowerCase() === 'single choice' &&
                <FormControl>
                <RadioGroup
                    className='radio-group'
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={item.options[0]}
                    name="radio-buttons-group"
                    row
                >
                    {item.options.map((option, index) => (
                        <FormControlLabel disabled value={option} control={<Radio />} label={option} key={index}/>
                    ))}
                </RadioGroup>
                </FormControl>
            }
            {item.question_type.toLowerCase() === 'multiple choice' &&
                <FormGroup className='checkbox-array'>
                {item.options.map((option, index) => (
                    <>
                        {index <= 1 &&
                            <FormControlLabel control={<Checkbox defaultChecked disabled />} label={option} />
                        }
                        {index > 1 &&
                            <FormControlLabel control={<Checkbox disabled />} label={option} />
                        }
                    </>
                ))}
                </FormGroup>
            }
        </Box>
    );
};

const Draggable = ({
    label,
    formattedLabel,
    item,
    items,
    setListData,
    id,
    index,
    isEditingCard,
    closeAllExcept,
    itemDeleteCallback,
    segmentSizeLimit,
    hasItemError,
    setHasItemError,
    hasListError,
    setHasListError,
    itemsWithErrors,
    isItemEmpty,
    isSortable }) => {

    let singleItem = items && items.length === 1;
    const api = useApi();
    // console.log('items:', items);
    const [isEditing, setIsEditing] = React.useState(isEditingCard);
    const [persistedError, setPersistedError] = React.useState(false);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable(item);
    // console.log('inside Draggable', label, item, id, index, isSortable);
    const itemStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleEditToggle = () => {
        console.log('edit clicked');
        setIsEditing(!isEditing);
        // set all other cards to not editing
        closeAllExcept(items.indexOf(item));
    }

    const handleDelete = async (id) => {
        console.log('delete clicked', id);
        console.log('items:', items);
        const newItems = items.filter((item) => item.id !== id);
        setListData(newItems);
        // state data is updated but we need to update the server data
        if (label === 'segment') {
            itemDeleteCallback(item.audience_segment_id);
        } else if (label === 'question') {
            itemDeleteCallback(item.question_id);
        }
    }

    const handleCopyItem = () => {
        // copy the item and add it to the end of list
        const newItem = { ...item, id: Math.floor(Math.random() * 10000), sort_order: items.length + 1 };
        if (label === 'segment') {
            newItem.audience_segment_id = "";
            newItem.created_at = "";
        } else if (label === 'question') {
            newItem.question_id = "";
            newItem.created_at = "";
        }
        setListData([...items, newItem]);
    }

    React.useEffect(() => {
        // toggles editing states of other cards
        setIsEditing(isEditingCard);
    }, [isEditingCard]);

    React.useEffect(() => {
        console.log('**** hasItemError changed', hasItemError);
    }, [hasItemError]);

    return (
        <>
            <Box className={`dynamic-card ${isEditing ? 'editing' : ''} ${(itemsWithErrors.includes(item) && !isItemEmpty(item)) ? 'has-error' : ''}`}
                isEditing={items.length === 1 ? true : isEditing}
                key={index}
                ref={setNodeRef}
                style={itemStyle}>
                {isSortable && (
                    <Box className="dynamic-card-handle">
                        <span {...attributes} {...listeners} className="dynamic-card-handle-target">
                            <DragIndicatorIcon />
                        </span>
                    </Box>
                )}
                <Box className="dynamic-card-header">
                    <span className="dynamic-card-title">
                        {formattedLabel} {index + 1} {index === 0 && ' (Required)'}</span>
                    <span className="dynamic-card-actions">
                        {!isEditing &&
                            <span className="dynamic-card-action edit" onClick={handleEditToggle}>
                                <EditIcon />
                            </span>
                        }
                        {!itemsWithErrors.includes(item) &&
                            <span className="dynamic-card-action copy" onClick={handleCopyItem}>
                                <ContentCopyIcon />
                            </span>
                        }
                        {items.indexOf(item) !== 0  &&
                            <span className="dynamic-card-action delete" onClick={() => handleDelete(item.id)}>
                                <DeleteOutlineOutlinedIcon />
                            </span>
                        }
                    </span>
                </Box>
                <Box className="dynamic-card-body">
                    {!isEditing && label === 'segment' && <SegmentViewLayout
                        item={item}/>
                    }
                    {!isEditing && label === 'question' && <QuestionViewLayout item={item}/>
                    }
                    {isEditing && label === 'segment' && <SegmentEditLayout
                        item={item}
                        items={items}
                        setListData={setListData}
                        segmentSizeLimit={segmentSizeLimit}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        persistedError={persistedError}
                        setPersistedError={setPersistedError}
                        label={label}/>
                    }
                    {isEditing && label === 'question' && <QuestionEditLayout
                        item={item}
                        items={items}
                        setListData={setListData}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        persistedError={persistedError}
                        setPersistedError={setPersistedError}
                        label={label}/>
                    }
                </Box>
            </Box>
        </>
    );
};

const Droppable = ({
    id,
    items,
    setListData,
    formattedLabel,
    isSortable,
    isEditingIndex,
    setIsEditingIndex,
    itemDeleteCallback,
    segmentSizeLimit,
    hasItemError,
    setHasItemError,
    hasListError,
    emptyItem,
    setHasListError,
    itemsWithErrors,
    label }) => {
    // console.log('inside Droppable', id, items);
    const closeAllExcept = (index) => {
        setIsEditingIndex(index);
    }

    const isItemEmpty = (item) => {
        // this function denotes an empty item for segments + questions
        // so that we dont mark newly added items that haven't been edited as erroneous
        if (label === 'segment') {
            return item.segment_name === '' && item.segment_size === '';
        } else if (label === 'question') {
            return item.question_text === '' && item.question_type === '' && item.options.length === 0;
        }
    }

    return (
        <Box className={`dynamic-card-list ${itemsWithErrors.length > 0 && !isItemEmpty(itemsWithErrors[0]) ? 'has-error' : ''}`}>
            <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
                {/* for each list item, render a DynamicCard */}
                {items.map((item, index) => (
                    <Draggable
                        key={item.id}
                        id={item.id}
                        item={item}
                        items={items}
                        setListData={setListData}
                        index={index}
                        label={label}
                        isEditingCard={items.indexOf(item) === isEditingIndex}
                        closeAllExcept={closeAllExcept}
                        removable={items.length > 1}
                        formattedLabel={formattedLabel}
                        itemDeleteCallback={itemDeleteCallback}
                        segmentSizeLimit={segmentSizeLimit}
                        hasItemError={hasItemError}
                        setHasItemError={setHasItemError}
                        hasListError={hasListError}
                        setHasListError={setHasListError}
                        itemsWithErrors={itemsWithErrors}
                        isItemEmpty={isItemEmpty}
                        isSortable={isSortable}/>

                ))}
            </SortableContext>
        </Box>
    );
};

const DynamicCardList = ({
    label = '',
    listData = [],
    setListData,
    emptyItem = {},
    itemDeleteCallback = () => {},
    segmentSizeLimit = 1000,
    itemsWithErrors = [],
    isSortable = false }) => {

    const api = useApi();
    const listId = label.toLowerCase().replace(' ', '-').concat('-dynamic-card-list');
    const formattedLabel = label ? label.charAt(0).toUpperCase() + label.slice(1) : '';
    const handleItemAdd = () => {
        console.log('add item clicked');
        const newItem = { ...emptyItem, id: Math.floor(Math.random() * 10000), sort_order: listData.length + 1 };
        setListData([...listData, newItem]);
        setIsEditingIndex(listData.length);
    };
    const [isEditingIndex, setIsEditingIndex] = React.useState(0);
    const [hasItemError, setHasItemError] = React.useState(false);
    const [hasListError, setHasListError] = React.useState(false);

    const handleDragStart = (e) => {
        console.log('drag start', e);
        console.log('listData', listData);
        // setListData(listData);
    };

    const handleDragEnd = (e) => {
        console.log('drag end', e);
        if (!e.over) return;
        console.log('listData start', listData);

        if (e.active.id !== e.over.id) {
            setListData((listData) => {
                // find the original index of the dragged item
                const oldIndex = listData.indexOf(listData.find(item => item.id.toString() === e.active.id.toString()));
                // find the index of the item that the dragged item is being dropped onto
                const newIndex = listData.indexOf(listData.find(item => item.id.toString() === e.over.id.toString()));
                console.log('oldIndex', oldIndex, 'newIndex', newIndex);
                const newArray = arrayMove(listData, oldIndex, newIndex);
                // newArray has correct index order but incorrect sort order, so sync them up
                newArray.map((item, index) => item.sort_order = index + 1);
                console.log('newArray', newArray);
                if (oldIndex === isEditingIndex) {
                    setIsEditingIndex(newIndex);
                }
                return newArray;
            });
            console.log('listData', listData);

        }
    }

    React.useEffect(() => {
        console.log('listData changed', listData);
        // setListData(listData);
        if (listData.length === 0) {
            setListData([{...emptyItem, id: Math.floor(Math.random() * 10000)}]);
        }
    }, [listData]);

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}>
            <Droppable
                id={listId}
                items={listData}
                setListData={setListData}
                label={label}
                formattedLabel={formattedLabel}
                emptyItem={emptyItem}
                isEditingIndex={isEditingIndex}
                setIsEditingIndex={setIsEditingIndex}
                itemDeleteCallback={itemDeleteCallback}
                segmentSizeLimit={segmentSizeLimit}
                hasItemError={hasItemError}
                setHasItemError={setHasItemError}
                hasListError={hasListError}
                setHasListError={setHasListError}
                itemsWithErrors={itemsWithErrors}
                isSortable={isSortable}/>
            <Box className="dynamic-card-list-controls">
            <Button
              data-test-id={`add-${label}-btn`}
              onClick={handleItemAdd}
              variant="contained"
              startIcon={<AddIcon />}
              style={{ marginTop: '20px' }}
            >
              Add Another {formattedLabel}
            </Button>
            </Box>
        </DndContext>);
}

export default DynamicCardList;