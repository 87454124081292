import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import CustomTooltip from '../ui/CustomTooltip';
import DynamicCardList from '../dynamic/DynamicCardList';
import TextField from '@mui/material/TextField';

const Audiences = ({
  activeStep,
  audienceData,
  setAudienceData,
  hasAudienceData,
  isShowAudienceError,
  surveyName,
  validateSurveyName,
  hasSurveyNameError,
  validateAudience,
  currentContext,
  segmentData,
  setSegmentData,
  hasSegmentData,
  addSegment,
  addSegmentLimit,
  newSegmentName,
  hasSegmentNameError,
  newSegmentSize,
  hasSegmentSizeError,
  hasSegmentCountError,
  segmentSizeLimit,
  setNewSegmentName,
  setNewSegmentSize,
  validateSegment,
  segmentActions,
  segmentDeleteCallback,
  segmentsWithErrors,
}) => {
  const emptySegment = {
    id: 0,
    audience_id: '',
    segment_name: '',
    segment_size: '',
    audience_segment_id: '',
    has_name_error: false,
    has_size_error: false,
    sort_order: 1
  };
  return (
    <Grid container>
      <Grid xs={12} md={12} lg={12} >
        <Container className="left-panel">
            <h2>
              Survey Name
              <CustomTooltip placement="right"
                title={
                  <React.Fragment>
                    Name your survey with enough detail to describe its objective,
                    for example: 'Coke vs Pepsi Taste Test v1.0'<br />
                    Survey name does not affect survey results.
                  </React.Fragment>
                } >
                <em className="info">i</em>
              </CustomTooltip>
            </h2>
            <p>Give your survey a unique name for later reference.</p>
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
                <TextField
                  id="survey-name"
                  label="Survey Name"
                  data-test-id="survey-name"
                  tabIndex="1"
                  fullWidth
                  error={hasSurveyNameError}
                  helperText={hasSurveyNameError ? 'Please add a survey name' : ''}
                  name='surveyName'
                  value={surveyName}
                  className={hasSurveyNameError ? 'has-error' : ''}
                  onChange={(e) => validateSurveyName(e.target.value)}
                  />
              </Box>
            </Box>
            <h2>
              Audience
              <CustomTooltip placement="right"
                title={
                  <React.Fragment>
                    Define any group you want to target.
                    <br />You can be specific or general depending on
                    <br />your objective.
                    <br />
                    For example, 'Soda drinkers in America' or
                    <br />'Car enthusiasts in California.'
                  </React.Fragment>
                } >
                <em className="info">i</em>
              </CustomTooltip>
            </h2>
            <p>Define the audience you want to conduct your research on.</p>
            <Box className="textBox" sx={{ mt: 2 }}>
              <Box className="textField-box">
              <TextField
                  id="audience"
                  label="Audience"
                  data-test-id="audience"
                  tabIndex="2"
                  fullWidth
                  error={isShowAudienceError}
                  helperText={isShowAudienceError ? 'Please add an audience' : ''}
                  value={audienceData[0]?.audience}
                  className={isShowAudienceError && !hasAudienceData ? 'has-error' : ''}
                  onChange={(e) => validateAudience(e.target.value)}
                  />
              </Box>
            </Box>
          <h2>
            Segments
            <CustomTooltip placement="right"
              title={
                  <React.Fragment>
                  Be as detailed as possible when describing
                  <br /> your segment. You can add multiple
                  <br />descriptors per field if needed.
                  <br />E.g People who drink Coke 1+ times per
                  <br />week AND work out 2+ times per week.
                  </React.Fragment>
              }>
              <em className="info">i</em>
            </CustomTooltip>
          </h2>
          <p>Create segments within your audience. These can be based on things like purchase or use frequency, demographics, psychographics, behaviors, or unique preferences. Each segment can be as specific as you like.</p>
          <p><strong> Create at least one Segment to set the number of respondents.</strong> If you do not want to segment your audience, simply copy / paste the exact description from your Audience into one Segment here.</p>
          <Grid item xs={12} md={10} lg={10}>
          <DynamicCardList
            label={'segment'}
            emptyItem={emptySegment}
            listData={segmentData}
            setListData={setSegmentData}
            itemDeleteCallback={segmentDeleteCallback}
            segmentSizeLimit={segmentSizeLimit}
            itemsWithErrors={segmentsWithErrors}
            isSortable={true}/>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default Audiences;