import * as React from 'react';
import { BarChartPro as BarChartPro } from '@mui/x-charts-pro/BarChartPro';

const BarChart = ({ data, datasets, labels}) => {
  // data = array of arrays, each contains a label and a raw count value ONLY for labels that have an answer value (empties are omitted)
  // datasets = object containing array "data" of raw counts converted to % for ALL labels, even if % = 0
  // labels = array of strings, ALL labels are present
  const primaryColor = '#0D49A0';
  // create list of truncated labels
  const truncatedLabels = labels.map((label) => {
    return label.length > 40 ? label.substring(0, 40) + '...' : label;
  });

  const combineData = (datasets, labels) => {
    return datasets.data.reduce((obj, item, index) => {
      obj[labels[index]] = item;
      return obj;
    }, {});
  }
  const fullData = combineData(datasets, labels);
  const longestLabel = truncatedLabels.reduce((a, b) => a.length > b.length ? a : b);

  return (
    <BarChartPro
      className='bar-chart-pro-container'
      yAxis={[{
        scaleType: 'band',
        data: Object.keys(fullData),
        valueFormatter: (value, context) => {
          return context.location === 'tick' ? truncatedLabels[labels.indexOf(value)] : value;
        }
      }]}
      xAxis={[{
        max: 100,
        tickInterval: [10,20,30,40,50,60,70,80,90,100],
        valueFormatter: (value) => `${value}%`,
      }]}
      series={[{
        data: Object.values(fullData),
        color: primaryColor,
        valueFormatter: (value) => `${value.toFixed(1)}%`,
      }]}
      layout={'horizontal'}
      xs={12} md={12} lg={8}
      borderRadius={5}
      margin={{
        left: parseInt(longestLabel.length * 2.5),
      }}
      style={{
        'overflow': 'visible'// override (style = inline, sx = appended CSS prop)
      }}
    />
  );
}

export default BarChart;